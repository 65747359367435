import { ArrowDownIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Text, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Card, CardBody, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { TableDatactAtom, supabaseAuthAtom } from '../../../atoms';
import Auth from '../../Auth';
import { useAtom } from 'jotai';
import { supabase } from '../../../supabaseClient';
import { useState } from 'react';

interface inboxProps {
    filteredTableData: any;
    title: string;
    sub: string
    unread: boolean
}

export function InboxTable({ filteredTableData, title, sub, unread }: inboxProps) {
    const [session] = useAtom(supabaseAuthAtom);
    const [isMessageModalOpen, setMessageModalOpen] = useState<boolean>(false)
    const [currentMsg, setCurrentMsg] = useState<string>('')
    const [tableData, setTableData] = useAtom(TableDatactAtom);

    if (!session) {
        return (<Auth />)
    }
    else {

        const fetchData = async () => {
            const { data, error }: any = await supabase
                .from('contact_form')
                .select()
            if (error) console.log(error);
            else setTableData(data);
        }

        const handleMaskAsRead = async (messageID: number) => {
            const { error: readTrueError } = await supabase
                .from('contact_form')
                .update({ read: true })
                .eq('id', messageID)
            if (readTrueError) console.log(readTrueError)
            else fetchData();
        }
        const handleMaskAsUnread = async (messageID: number) => {
            const { error: readFalseError } = await supabase
                .from('contact_form')
                .update({ read: false })
                .eq('id', messageID)
            if (readFalseError) console.log(readFalseError)
            else fetchData();
        }

        const triggerMessageModal = (currentID: number) => {
            const tmpMessage = filteredTableData.find((obj: any) => {
                return (obj.id === currentID)
            }).message
            setCurrentMsg(tmpMessage)
            setMessageModalOpen((prev) => !prev)
        }

        return (
            <>
                <Modal isCentered isOpen={isMessageModalOpen} onClose={() => setMessageModalOpen((prev) => !prev)} size='4xl'>
                    <ModalOverlay
                        bg='blackAlpha.300'
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                    />
                    <ModalContent>
                        <ModalCloseButton color='brand.900' />
                        <ModalHeader>Full Message:</ModalHeader>
                        <ModalBody>
                            
                               {currentMsg}
                        </ModalBody>
                        <ModalFooter>
                            <Button mt='3rem' bg="brand.900" color={'black'}
                                _after={{
                                    transition: 'all .3s ease',
                                    content: '""',
                                    w: 'full',
                                    h: 'full',
                                    pos: 'absolute',
                                    top: 2,
                                    left: 0,
                                    backgroundColor: 'brand.900',
                                    filter: 'blur(20px)',
                                    zIndex: -1,
                                }}
                                _groupHover={{
                                    _after: {
                                        filter: 'blur(25px)',
                                    },
                                }} onClick={() => setMessageModalOpen((prev) => !prev)}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                <Card>
                    <CardBody>
                        <Accordion defaultIndex={[0]} allowMultiple>
                            <AccordionItem border='none'>
                                <h2>
                                    <AccordionButton color={'black'} _hover={{ color: 'grey' }}>
                                        <Box as="span" flex='1' textAlign='left' color={'brand.900'}>
                                            <Text as={'b'} fontSize={'lg'}>{title}</Text>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Card>
                                        <CardBody>

                                            <TableContainer>
                                                <Table variant='simple' size={'sm'}>
                                                    <TableCaption color={'brand.900'}>{sub}</TableCaption>
                                                    <Thead  >
                                                        <Tr>
                                                            <Th color={'brand.900'}>Name</Th>
                                                            <Th color={'brand.900'}>Email</Th>
                                                            <Th color={'brand.900'}>Date</Th>
                                                            <Th color={'brand.900'}>Message</Th>
                                                            <Th><ArrowDownIcon float={'right'} mb={1} mr={3} /></Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {filteredTableData.map((rowData: any) => (
                                                            <Tr key={`${rowData.email}-${rowData.created_at}-${rowData.id}`}>
                                                                <Td>{rowData.name}</Td>
                                                                <Td>{rowData.email}</Td>
                                                                <Td>{new Date(Date.parse(rowData.created_at.toString())).toLocaleString('de-DE')}</Td>
                                                                <Td><Tooltip borderRadius={'md'} label='Read full message'>
                                                                    <Button color={'black'} _hover={{ backgroundColor: 'brand.900', color: 'white' }} variant={'outline'} onClick={() => triggerMessageModal(rowData.id)}>
                                                                        {rowData.message.slice(0, 10) + '...'}
                                                                    </Button>
                                                                </Tooltip>
                                                                </Td>
                                                                <Td >
                                                                    <Tooltip borderRadius={'md'} label={unread ? 'Mark ad read' : 'Mark ad unread'}><IconButton size={'sm'}
                                                                        float={'right'}
                                                                        variant='outline'
                                                                        colorScheme='green'
                                                                        aria-label={unread ? 'Mark ad read' : 'Mark ad unread'}
                                                                        icon={unread ? <ViewIcon /> : <ViewOffIcon />}
                                                                        onClick={() => {
                                                                            if (unread === true) { handleMaskAsRead(rowData.id) }
                                                                            else { handleMaskAsUnread(rowData.id) }
                                                                        }
                                                                        }
                                                                        _hover={{
                                                                            backgroundColor: 'brand.900',
                                                                        }} />
                                                                    </Tooltip>
                                                                </Td>
                                                            </Tr>
                                                        ))}

                                                    </Tbody>

                                                </Table>
                                            </TableContainer>
                                        </CardBody>
                                    </Card>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </CardBody>
                </Card>
            </>
        )
    }
}
