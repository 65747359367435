import { ArrowDownIcon, ViewIcon } from '@chakra-ui/icons';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Card, CardBody, Container, Divider, IconButton, Portal, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr, VStack, useDisclosure } from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableDatactAtom, isNavbarFixedAtom, sidebarVariantAtom, supabaseAuthAtom } from "../../../atoms";
import { supabase } from '../../../supabaseClient';
import Auth from '../../Auth';
import { SimpleNav } from '../../navigation/SimpleNav';
import { InboxTable } from './InboxTable'
import AdminNavbar from '../Navbars/AdminNavbar';
import Sidebar from '../Sidebar';
import { useActiveRoute } from '../../../hooks/useActiveRoute';
import routes from '../routes';


interface contactObj { name: string, email: string, message: string, read: boolean };

export function InboxPage() {
    const navigate = useNavigate()
    const [session] = useAtom(supabaseAuthAtom);
    const [tableData, setTableData] = useAtom(TableDatactAtom);
    const [readTableData, setReadTableData] = useState<any>([]);
    const [unreadTableData, setUnreadTableData] = useState<any>([]);
    const sidebarVariant = useAtomValue(sidebarVariantAtom);
    const fixed = useAtomValue(isNavbarFixedAtom);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const activeRoute = useActiveRoute(routes);

    const fetchData = async () => {
        const { data, error }: any = await supabase
            .from('contact_form')
            .select()
        if (error) console.log(error);
        else setTableData(data);
    }

    useEffect(() => {
        fetchData();
    }, [])
    useEffect(() => {
        setUnreadTableData((tableData || []).filter((obj: any) => obj.read !== true));
        setReadTableData((tableData || []).filter((obj: any) => obj.read === true));
    }, [tableData])

    if (!session) {
        return (<Auth />)
    }
    else {

        return (
            <>
                <Sidebar
                    routes={routes}
                    logoText={'ADMIN PANEL'}
                    display='none'
                    sidebarVariant={sidebarVariant}
                />

                <Container ml={{
                    base: '0',
                    xl: '285px'
                }}>
                    <Portal>
                        <AdminNavbar
                            onOpen={onOpen}
                            brandText={activeRoute}
                            secondary={false}
                            fixed={true}
                        />
                    </Portal>
                    <Box
                        pt={'7rem'} mx={'0.2rem'} w={"calc(100vw - 60px - 275px)"}>
                        <InboxTable filteredTableData={unreadTableData} title={'Unread Messages'} sub='Messages from Website - To be Read' unread={true} />
                        <Divider orientation='horizontal' my={4} />
                        <InboxTable filteredTableData={readTableData} title={'Read Messages'} sub='Messages from Website - Already Read' unread={false} />

                    </Box>
                </Container>

            </>
        );
    }
}