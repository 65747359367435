import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Card, CardBody, CardFooter, Center, Divider, Input, InputGroup, InputLeftElement, SimpleGrid, Spinner, Text, Textarea, VStack, useToast } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { contentDataAtom, supabaseAuthAtom } from '../../../atoms';
import { supabase } from '../../../supabaseClient';
import Auth from '../../Auth';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';

interface textAreaProps {
    label: string;
}

export function EditCard
    ({ currentID }: any) {
    const [session] = useAtom(supabaseAuthAtom);
    const [isMessageModalOpen, setMessageModalOpen] = useState<boolean>(false)
    const [currentObj, setCurrentObj] = useState<any>()
    const [othersObj, setOthersObj] = useState<any>()
    const [contentDataStored, setContentDataStored] = useAtom(contentDataAtom);
    const [contentData, setContentData] = useState<any>();
    const toast = useToast()
    const possibleItems: string[] = ['header', 'who_i_am', 'contacts', 'services', 'slider', 'contact_labels', 'sections_labels'];

    useEffect(() => {
        setContentData(contentDataStored)
    }, [contentDataStored])

    useEffect(() => {
        console.log(contentDataStored)
        setCurrentObj((contentData || []).filter((obj: any) => obj.id === currentID)[0]);
        setOthersObj((contentData || []).filter((obj: any) => obj.id !== currentID));
    }, [contentData])

    if (!session) {
        return (<Auth />)
    }
    else {
        const handleInputChange = (e: any, label: string) => {
            let inputValue = e.target.value
            const tmpObj = currentObj;
            tmpObj[label] = inputValue;
            setCurrentObj(tmpObj)
            setContentData([...othersObj, tmpObj])
        }

        const updateCurrentCard = () => {
            setContentDataStored(contentData)
            toast({
                title: 'Saved Locally',
                description: "Changes has been saved Locally!",
                status: 'info',
                duration: 3000,
                isClosable: true,
            })
        }

        return (
            <>
                {currentObj?.id ?
                    <Card py={0}>
                        <CardBody py={1}>
                            <Accordion allowToggle>
                                <AccordionItem border='none'>
                                    <h2>
                                        <AccordionButton color={'black'} _hover={{ color: 'grey' }}>
                                            <Box as="span" flex='1' textAlign='left' color={'brand.900'} >
                                                <Text as={'b'} fontSize={'lg'}>{currentObj.id}</Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Card>
                                            <CardBody>

                                                <SimpleGrid columns={2} spacing={10}>
                                                    {(possibleItems.includes(currentObj.id)) &&
                                                        (currentObj.id === possibleItems[0] || currentObj.id === possibleItems[1]) ?
                                                        (

                                                            <>
                                                                <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_title`}>
                                                                    <Text as='samp' mx='8px'>{'Title'}</Text>
                                                                    <Textarea
                                                                        borderRadius={'md'}
                                                                        value={currentObj['title']}
                                                                        onChange={(e) => handleInputChange(e, 'title')}
                                                                        placeholder={'type here ...'}
                                                                        size='sm'
                                                                    />
                                                                </VStack>
                                                                {currentObj.id === 'header' &&
                                                                    <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_subtitle`}>
                                                                        <Text as='samp' mx='8px'>{'subtitle'}</Text>
                                                                        <Textarea
                                                                            borderRadius={'md'}
                                                                            value={currentObj['Subtitle']}
                                                                            onChange={(e) => handleInputChange(e, 'subtitle')}
                                                                            placeholder={'type here ...'}
                                                                            size='sm'
                                                                        />
                                                                    </VStack>
                                                                }
                                                                <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_description`}>
                                                                    <Text as='samp' mx='8px'>{'Description'}</Text>
                                                                    <Textarea
                                                                        borderRadius={'md'}
                                                                        value={currentObj['description']}
                                                                        onChange={(e) => handleInputChange(e, 'description')}
                                                                        placeholder={'type here ...'}
                                                                        size='sm'
                                                                    />
                                                                </VStack>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                {(currentObj.id === possibleItems[2]) &&
                                                                    <>
                                                                        <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_title`}>
                                                                            <Text as='samp' mx='8px'>{'Phone number'}</Text>
                                                                            <InputGroup>
                                                                                <InputLeftElement pointerEvents='none'>
                                                                                    <PhoneIcon color='gray.300' />
                                                                                </InputLeftElement>
                                                                                <Input type='tel'
                                                                                    value={currentObj['title']}
                                                                                    onChange={(e) => handleInputChange(e, 'title')}
                                                                                    placeholder='Phone number' />
                                                                            </InputGroup>
                                                                        </VStack>
                                                                        <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_subtitle`}>
                                                                            <Text as='samp' mx='8px'>{'Email'}</Text>
                                                                            <InputGroup>
                                                                                <InputLeftElement pointerEvents='none'>
                                                                                    <EmailIcon color='gray.300' />
                                                                                </InputLeftElement>
                                                                                <Input type='tel'
                                                                                    value={currentObj['subtitle']}
                                                                                    onChange={(e) => handleInputChange(e, 'subtitle')}
                                                                                    placeholder='Email' />
                                                                            </InputGroup>
                                                                        </VStack>
                                                                        <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_description`}>
                                                                            <Text as='samp' mx='8px'>{'Address'}</Text>
                                                                            <InputGroup>
                                                                                <InputLeftElement pointerEvents='none'>
                                                                                    <EmailIcon color='gray.300' />
                                                                                </InputLeftElement>
                                                                                <Input type='tel'
                                                                                    value={currentObj['description']}
                                                                                    onChange={(e) => handleInputChange(e, 'description')}
                                                                                    placeholder='Address' />
                                                                            </InputGroup>
                                                                        </VStack>
                                                                    </>
                                                                }
                                                                {(currentObj.id === possibleItems[5]) &&
                                                                    <>
                                                                        <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_title`}>
                                                                            <Text as='samp' mx='8px'>{'[Contact me] label'}</Text>
                                                                            <Textarea
                                                                                borderRadius={'md'}
                                                                                value={currentObj['title']}
                                                                                onChange={(e) => handleInputChange(e, 'title')}
                                                                                placeholder={'type here ...'}
                                                                                size='sm'
                                                                            />
                                                                        </VStack>
                                                                        <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_subtitle`}>
                                                                            <Text as='samp' mx='8px'>{'[Services] label'}</Text>
                                                                            <Textarea
                                                                                borderRadius={'md'}
                                                                                value={currentObj['Subtitle']}
                                                                                onChange={(e) => handleInputChange(e, 'subtitle')}
                                                                                placeholder={'type here ...'}
                                                                                size='sm'
                                                                            />
                                                                        </VStack>
                                                                        <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_description`}>
                                                                            <Text as='samp' mx='8px'>{'[Who i am] label'}</Text>
                                                                            <Textarea
                                                                                borderRadius={'md'}
                                                                                value={currentObj['description']}
                                                                                onChange={(e) => handleInputChange(e, 'description')}
                                                                                placeholder={'type here ...'}
                                                                                size='sm'
                                                                            />
                                                                        </VStack>
                                                                    </>
                                                                }

                                                                {(currentObj.id === possibleItems[6]) &&
                                                                    <>
                                                                        <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_title`}>
                                                                            <Text as='samp' mx='8px'>{'Engage phrase label'}</Text>
                                                                            <Textarea
                                                                                borderRadius={'md'}
                                                                                value={currentObj['title']}
                                                                                onChange={(e) => handleInputChange(e, 'title')}
                                                                                placeholder={'type here ...'}
                                                                                size='sm'
                                                                            />
                                                                        </VStack>
                                                                        <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_subtitle`}>
                                                                            <Text as='samp' mx='8px'>{'[Name] label'}</Text>
                                                                            <Textarea
                                                                                borderRadius={'md'}
                                                                                value={currentObj['Subtitle']}
                                                                                onChange={(e) => handleInputChange(e, 'subtitle')}
                                                                                placeholder={'type here ...'}
                                                                                size='sm'
                                                                            />
                                                                        </VStack>
                                                                        <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_description`}>
                                                                            <Text as='samp' mx='8px'>{'[Message] label'}</Text>
                                                                            <Textarea
                                                                                borderRadius={'md'}
                                                                                value={currentObj['description']}
                                                                                onChange={(e) => handleInputChange(e, 'description')}
                                                                                placeholder={'type here ...'}
                                                                                size='sm'
                                                                            />
                                                                        </VStack>
                                                                        <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_various`}>
                                                                            <Text as='samp' mx='8px'>{'Button label'}</Text>
                                                                            <Textarea
                                                                                borderRadius={'md'}
                                                                                value={currentObj['various']}
                                                                                onChange={(e) => handleInputChange(e, 'various')}
                                                                                placeholder={'type here ...'}
                                                                                size='sm'
                                                                            />
                                                                        </VStack>
                                                                    </>
                                                                }
                                                                {
                                                                (currentObj.id === possibleItems[4]) &&
                                                                    <>
                                                                        <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_title`}>
                                                                            <Text as='samp' mx='8px'>{'upper text'}</Text>
                                                                            <Textarea
                                                                                borderRadius={'md'}
                                                                                value={currentObj['title']}
                                                                                onChange={(e) => handleInputChange(e, 'title')}
                                                                                placeholder={'type here ...'}
                                                                                size='sm'
                                                                            />
                                                                        </VStack>
                                                                        <VStack align={'flex-start'} key={`${currentID}_${currentObj.id}_subtitle`}>
                                                                            <Text as='samp' mx='8px'>{'lower text'}</Text>
                                                                            <Textarea
                                                                                borderRadius={'md'}
                                                                                value={currentObj['subtitle']}
                                                                                onChange={(e) => handleInputChange(e, 'subtitle')}
                                                                                placeholder={'type here ...'}
                                                                                size='sm'
                                                                            />
                                                                        </VStack>
                                                                    </>
                                                                }
                                                            </>
                                                        )
                                                    }

                                                </SimpleGrid>

                                            </CardBody>
                                            <CardFooter justifyContent={'flex-end'}>
                                                <Button bg="brand.900" color={'black'}
                                                    _after={{
                                                        transition: 'all .3s ease',
                                                        content: '""',
                                                        w: 'full',
                                                        h: 'full',
                                                        pos: 'absolute',
                                                        top: 2,
                                                        left: 0,
                                                        backgroundColor: 'brand.900',
                                                        filter: 'blur(20px)',
                                                        zIndex: -1,
                                                    }}
                                                    _groupHover={{
                                                        _after: {
                                                            filter: 'blur(25px)',
                                                        },
                                                    }}
                                                    onClick={() => { updateCurrentCard() }} >
                                                    Save local
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </CardBody>
                    </Card>

                    :
                    <Center>
                        <Spinner color='brand.900' size={'lg'} />
                    </Center>
                }

                <Divider orientation='horizontal' my={4} />
            </>
        )

    }
}
