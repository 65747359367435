// AnimatedArrowDown.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { ArrowDownIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { Flex, IconButton } from '@chakra-ui/react';

const AnimatedArrowDown = () => {
    const arrowMotion = {
        y: [0, 10, 0], // Anima su e giù
        transition: {
            y: {
                duration: 1,
                yoyo: Infinity, // L'animazione si ripete all'infinito
            },
        },
    };

    return (
        <Flex
            align="center"
            justify="center"
            height="5vh"
            width="100%"
            mt={'5rem'}
        >
            <motion.div animate={arrowMotion}>
                <IconButton
                    aria-label="Scroll down"
                    icon={<ArrowRightIcon color={'brand.900'} style={{ transform: 'rotate(90deg)' }} />}
                    bgColor="transparent"
                    _hover={{ bgColor: 'transparent' }}
                    _active={{ bgColor: 'transparent' }}

                />
            </motion.div>
        </Flex>
    );
};

export default AnimatedArrowDown;
