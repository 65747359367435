import {
    Container,
    Flex,
    Box,
    Heading,
    Text,
    HStack,
    Button,
    VStack,
    Wrap,
    WrapItem,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    Center,
    Hide,
    Show,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react'
import {
    MdPhone,
    MdEmail,
    MdLocationOn,
    MdMailOutline,
} from 'react-icons/md';
import { BsPerson } from 'react-icons/bs';
import { createClient } from "@supabase/supabase-js";
import { useState } from 'react';
import { supabase } from '../supabaseClient'
import { AiOutlineWhatsApp } from 'react-icons/ai';

interface contactData {
    name: string;
    email: string;
    body: string;
};

interface supabaseInsertResponse {
    status: number;
    statusText: string;
}

export default function contact() {
    return (
        <Container maxW="full" mt={0} centerContent overflow="hidden" >
            <Flex>
                <Box
                    bg='#154975'
                    boxShadow={['xl', 'xl', 'dark-lg']}
                    color="white"
                    borderRadius="lg"
                    m={{ sm: 4, md: 16, lg: 10 }}
                    p={{ sm: 5, md: 5, lg: 16 }}>
                    <Box p={4}>
                        <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }} alignItems={'center'}>
                            <Hide below='lg'>
                                <HStack>
                                    <ItemsUnalligned />
                                </HStack>
                            </Hide>
                            <Show below='lg'>
                                <ItemsUnalligned />
                            </Show>
                        </Wrap>
                    </Box>
                </Box>
            </Flex>
        </Container>
    );
}

const ItemsUnalligned = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const toast = useToast()

    const handleNameChange = (e: any) => {
        setName(e.target.value)
    }
    const handleEmailChange = (e: any) => {
        setEmail(e.target.value)
    }
    const handleMessageChange = (e: any) => {
        setMessage(e.target.value)
    }
    async function postContactForm() {
        const contactObj = { name: name, email: email, message: message, read: false };
        const { data, error }: any = await supabase.from("contact_form").insert(contactObj);
        if (error) {
            console.log(error)
            toast({
                title: 'Riprova',
                description: "Se ancora non risci contattami tramite e-mail o cellulare",
                status: 'warning',
                duration: 3000,
                isClosable: true,
            })
        } else {
            toast({
                title: 'Messaggio Inviato!',
                description: "La tua richiesta è stata inoltrata",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            setName('')
            setEmail('')
            setMessage('')
        }
    }

    const openMailTo = (email: string) => {
        window.open(`mailto:${email}`, '_blank', 'noopener,noreferrer');
    }

    const whatsappLink = `https://wa.me/393402883373?text=Ciao%20Emanuele%20ho%20visto%20il%20tuo%20sito%20web%20e%20vorrei%20proporti:`
    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    const callUrl = 'tel:393402883373';
    const openCall = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    const placeUrl = 'https://www.google.com/maps/place/20063+Cernusco+sul+Naviglio+MI'

    return (
        <>
            <WrapItem mr={6}>
                <Box textAlign='center'>
                    <Heading>Contattami</Heading>
                    <Text mt={{ sm: 3, md: 3, lg: 5 }} color="whiteAlpha.900">
                        Contattami tramite questo form, ti risponderò alla email indicata!
                    </Text>
                    <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                        <VStack pl={0} spacing={3} alignItems="center">
                            <Button leftIcon={<AiOutlineWhatsApp size={'2rem'} />}
                                size="md"
                                height="48px"
                                width="270px"
                                bg="brand.900"
                                color={'black'}
                                _after={{
                                    transition: 'all .3s ease',
                                    content: '""',
                                    w: 'full',
                                    h: 'full',
                                    pos: 'absolute',
                                    top: 2,
                                    left: 0,
                                    backgroundColor: 'brand.900',
                                    filter: 'blur(20px)',
                                    zIndex: -1,
                                }}
                                _groupHover={{
                                    _after: {
                                        filter: 'blur(25px)',
                                    },
                                }}
                                onClick={() => openInNewTab(whatsappLink)}>
                                Whatsapp
                            </Button>
                            <Button
                                size="md"
                                height="48px"
                                width="270px"
                                variant="ghost"
                                borderColor={'brand.900'}
                                color="#DCE2FF"
                                _hover={{ border: '2px solid #1C6FEB' }}
                                leftIcon={<MdPhone size="20px" />}
                                onClick={() => openCall(callUrl)}>
                                +39-340 288 3373
                            </Button>
                            <Button
                                size="md"
                                height="48px"
                                width="270px"
                                variant="ghost"
                                borderColor={'brand.900'}
                                color="#DCE2FF"
                                _hover={{ border: '2px solid #1C6FEB' }}
                                leftIcon={<MdEmail size="20px" />}
                                onClick={() => openMailTo('emanuele.vigano1@gmail.com')}>
                                emanuele.vigano1@gmail.com
                            </Button>
                            <Button
                                size="md"
                                height="48px"
                                width="270px"
                                variant="ghost"
                                borderColor={'brand.900'}
                                color="#DCE2FF"
                                _hover={{ border: '2px solid #1C6FEB' }}
                                leftIcon={<MdLocationOn size="20px" />}
                                onClick={() => openInNewTab(placeUrl)}>
                                Cernusco sul naviglio
                            </Button>
                        </VStack>
                    </Box>

                </Box>
            </WrapItem>
            <WrapItem alignItems="center">
                <Box bg="white" borderRadius="lg" w={['100%', '20rem', '100%', '100%', '30rem']}>
                    <Box m={8} color="#0B0E3F">
                        <VStack spacing={5}>
                            <FormControl id="name">
                                <FormLabel>Nome</FormLabel>
                                <InputGroup borderColor="#E0E1E7">
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<BsPerson color="gray.800" />}
                                    />
                                    <Input type="text" size="md" value={name} onChange={handleNameChange} />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="name">
                                <FormLabel>Email</FormLabel>
                                <InputGroup borderColor="#E0E1E7">
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<MdMailOutline color="gray.800" />}
                                    />
                                    <Input type="text" size="md" value={email} onChange={handleEmailChange} />
                                </InputGroup>
                            </FormControl>
                            <FormControl id="name">
                                <FormLabel>Messaggio</FormLabel>
                                <Textarea
                                    borderColor="gray.300"
                                    _hover={{
                                        borderRadius: 'gray.300',
                                    }}
                                    placeholder="message"
                                    value={message} onChange={handleMessageChange}
                                />
                            </FormControl>
                            <FormControl id="name" float="right">
                                <Button onClick={() => postContactForm()}
                                    bg="brand.900"
                                    color={'white'}
                                    _hover={{}}>
                                    Invia Messaggio
                                </Button>
                            </FormControl>
                        </VStack>
                    </Box>
                </Box>
            </WrapItem>
        </>
    )
};

