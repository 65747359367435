import { Box, Button, Container, Divider, Flex, Portal, useDisclosure, useToast } from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { TableDatactAtom, contentDataAtom, isNavbarFixedAtom, sidebarVariantAtom, supabaseAuthAtom } from "../../../atoms";
import { supabase } from '../../../supabaseClient';
import Auth from '../../Auth';
import { SimpleNav } from '../../navigation/SimpleNav';
import { EditCard } from './EditCard';
import AdminNavbar from '../Navbars/AdminNavbar';
import Sidebar from '../Sidebar';
import routes from '../routes';
import { useActiveRoute } from '../../../hooks/useActiveRoute';


export function EditPage() {
    const [session] = useAtom(supabaseAuthAtom);
    const [contentDataStored, setContentDataStored] = useAtom(contentDataAtom);
    const [contentData, setContentData] = useState<any>(contentDataStored);
    const toast = useToast()
    const sidebarVariant = useAtomValue(sidebarVariantAtom);
    const fixed = useAtomValue(isNavbarFixedAtom);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const activeRoute = useActiveRoute(routes);

    const fetchData = async () => {
        const { data, error }: any = await supabase
            .from('website_conten')
            .select()
        if (error) {
            console.log(error)
            toast({
                title: 'Try Again',
                description: "Probably Invalid Credentials",
                status: 'warning',
                duration: 3000,
                isClosable: true,
            })
        }
        else {
            console.log(data)
            setContentDataStored(data)
            setContentData(data)
        };
    }
    const pushData = async () => {
        const { data, error }: any = await supabase
            .from('website_conten')
            .upsert(contentDataStored)
        if (error) {
            console.log(error)
            toast({
                title: 'Try Again',
                description: "Probably Invalid Credentials",
                status: 'warning',
                duration: 3000,
                isClosable: true,
            })
        }
        else {
            toast({
                title: 'Push successful',
                description: "Changes has been saved!",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        };
    }



    useEffect(() => {
        fetchData();
    }, [])

    if (!session) {
        return (<Auth />)
    }
    else {

        return (
            <>
                <Sidebar
                    routes={routes}
                    logoText={'ADMIN PANEL'}
                    display='none'
                    sidebarVariant={sidebarVariant}
                />

                <Container ml={{
                    base: '0',
                    xl: '285px'
                }}>
                    <Portal>
                        <AdminNavbar
                            onOpen={onOpen}
                            brandText={activeRoute}
                            secondary={false}
                            fixed={true}
                        />
                    </Portal>
                    <Box
                        pt={'7rem'} mx={'0.2rem'} w={"calc(100vw - 60px - 275px)"}>

                        <EditCard currentID={'header'} key={`${'header'}`} />
                        <EditCard currentID={'who_i_am'} key={`${'who_i_am'}`} />
                        <EditCard currentID={'contacts'} key={`${'contacts'}`} />
                        <EditCard currentID={'contact_labels'} key={`${'contact_labels'}`} />
                        <EditCard currentID={'sections_labels'} key={`${'sections_labels'}`} />
                        <EditCard currentID={'services'} key={`${'services'}`} />
                        <EditCard currentID={'slider'} key={`${'slider'}`} />
                    </Box>
                    <Flex h={'3rem'} borderColor={'black'} w={'97%'} border={2} justifyContent={'flex-end'}>
                        <Button bg="brand.900" color={'black'}
                            _after={{
                                transition: 'all .3s ease',
                                content: '""',
                                w: 'full',
                                h: 'full',
                                pos: 'absolute',
                                top: 2,
                                left: 0,
                                backgroundColor: 'brand.900',
                                filter: 'blur(20px)',
                                zIndex: -1,
                            }}
                            _groupHover={{
                                _after: {
                                    filter: 'blur(25px)',
                                },
                            }} onClick={pushData}>Push local</Button>
                    </Flex>
                </Container>

            </>
        );
    }
}