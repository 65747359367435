'use client'

import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Image,
    Stack,
    Button,
    Heading,
    useColorModeValue,
    Container,
    useToast,
} from '@chakra-ui/react'
import footerLogo from '../loghi/logo_ema_noicon_crop.png';
import { UnlockIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAtom } from 'jotai';
import { supabaseAuthAtom, userAtom } from '../atoms';
import { supabase } from '../supabaseClient';

export default function Auth() {
    const navigate = useNavigate();
    const [name, setName] = useState<string>('')
    const [psw, setPsw] = useState<string>('')
    const toast = useToast()
    const [user, setUser] = useAtom(userAtom);
    const [sessionAuth, setSessionAuth] = useAtom(supabaseAuthAtom);

    const handleNameChange = (e: any) => {
        setName(e.target.value)
    }
    const handlePswChange = (e: any) => {
        setPsw(e.target.value)
    }

    const signInWithEmail = async () => {
        console.log(name,psw)
        const { data, error } = await supabase.auth.signInWithPassword({
            email: name,
            password: psw,
        })
        if(error) {
            console.log(error)
            toast({
                title: 'Try Again',
                description: "Invalid Credentials",
                status: 'warning',
                duration: 3000,
                isClosable: true,
              })
        } else {
            console.log(data)
            toast({
                title: 'Login successful',
                description: "Enjoy every authenticated feature!",
                status: 'success',
                duration: 3000,
                isClosable: true,
              })
              setName('')
              setPsw('')
              const { data: authData } = supabase.auth.onAuthStateChange((event: any, session: any) => {
                if (event === "SIGNED_IN") {
                  setUser(session.user);
                  setSessionAuth(true);
                }
            })
              //navigate('/admin/:inbox')
         }
    }

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={4} px={6}>
                <Stack align={'center'} >
                    <Container
                        as={Stack}
                        maxW={'8xl'}
                        mx={8}
                        justify={'center'}
                        align={'center'}>
                        <Image src={footerLogo} boxSize='8rem' objectFit='contain' zIndex={2} />
                    </Container>
                    <Heading fontSize={'2xl'}>Sign in to your account</Heading>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={2}>
                        <FormControl id="email">
                            <FormLabel>Email address</FormLabel>
                            <Input type="email" value={name} onChange={handleNameChange} />
                        </FormControl>
                        <FormControl id="password">
                            <FormLabel>Password</FormLabel>
                            <Input type="password" value={psw} onChange={handlePswChange} />
                        </FormControl>
                        <Stack spacing={10}>
                            <Button mt='3rem' leftIcon={<UnlockIcon />} bg="brand.900" color={'black'}
                                _after={{
                                    transition: 'all .3s ease',
                                    content: '""',
                                    w: 'full',
                                    h: 'full',
                                    pos: 'absolute',
                                    top: 2,
                                    left: 0,
                                    backgroundColor: 'brand.900',
                                    filter: 'blur(20px)',
                                    zIndex: -1,
                                }}
                                _groupHover={{
                                    _after: {
                                        filter: 'blur(25px)',
                                    },
                                }}
                                onClick={() => {signInWithEmail()}} >
                                Sign in
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    )
}