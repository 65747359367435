/* eslint-disable */
import { ReactComponent as HouseLogo } from "../loghi/logo_ema.svg"

interface sizeProps {
    height?: number;
    width?: number;
}
export default function HouseAnimate({height, width}: sizeProps) {
    if( height && width) return (<HouseLogo style={{height:`${height}px`, width:`${width}px` }}/>)
    return (
        <HouseLogo style={{height:'350px', width:'350px' }}/>)
}