import {
    Box,
    Container,
    HStack,
    Heading,
    Image,
    SpaceProps,
    Tag,
    TagLabel,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import React from 'react';
import profile from '../img/ema_profile.jpg';
import { supabase } from '../supabaseClient'
import { ArrowForwardIcon } from '@chakra-ui/icons';

interface BlogAuthorProps {
    date: Date;
    name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
    return (
        <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
            <Image
                borderRadius="full"
                boxSize="40px"
                src="https://100k-faces.glitch.me/random-image"
                alt={`Avatar of ${props.name}`}
            />
            <Text fontWeight="medium">{props.name}</Text>
            <Text>—</Text>
            <Text>{props.date.toLocaleDateString()}</Text>
        </HStack>
    );
};

const AboutMe = () => {
    const descriptionText = '<p><strong>Laureato magistrale</strong> in Scienze agrarie e <strong>Agronomo </strong>iscritto all&rsquo;albo, da anni lavoro nel campo del verde ornamentale con specializzazione sul mondo degli alberi.</p><br/><p>Oltre ai servizi proposti in questo sito in qualit&agrave; di <strong>Arboricoltore</strong>, svolgo attivit&agrave; come Agronomo riuscendo cos&igrave; ad occuparmi a 360&deg; delle servizi richiesti per la <strong>gestione degli alberi</strong>.</p>'

    const { data: cvurl } = supabase
        .storage
        .from('public_doc')
        .getPublicUrl('CV_Emanuele_Vigano.pdf');

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <Container maxW={'5xl'} px="12" my={0}>

            <Box
                marginTop={{ base: '1', sm: '5' }}
                display="flex"
                flexDirection={{ base: 'column', sm: 'row' }}
                justifyContent="space-between">
                <Box
                    display="flex"
                    flex="1"
                    mr="0"
                    position="relative"
                    alignItems="center">
                    <Box
                        width={{ base: '100%', sm: '85%' }}
                        zIndex="2"
                        marginLeft={{ base: '0', sm: '5%' }}
                        marginTop="5%"
                        mr="0">
                        <Image
                            boxShadow='dark-lg'
                            borderRadius="lg"
                            src={profile}
                            alt="image not found, sorry"
                            width='16rem'
                            position='relative'
                            _after={{
                                transition: 'all .3s ease',
                                content: '""',
                                w: 'full',
                                h: 'full',
                                pos: 'absolute',
                                top: 2,
                                left: 0,
                                backgroundColor: 'brand.900',
                                filter: 'blur(20px)',
                                zIndex: 1,
                            }}
                            _groupHover={{
                                _after: {
                                    filter: 'blur(25px)',
                                },
                            }}
                        />
                    </Box>

                    <Box zIndex="-1" width="90%" position="absolute" height="100%">
                        <Box
                            bgGradient={useColorModeValue(
                                'radial(brand.200 1px, transparent 1px)',
                                'radial(brand.900 1px, transparent 1px)'
                            )}
                            backgroundSize="20px 20px"
                            opacity="2"
                            height="100%"
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flex="1"
                    flexDirection="column"
                    justifyContent="center"
                    marginTop={{ base: '3', sm: '0' }}>
                    <Heading marginTop="1">
                        <Text
                            as={'span'}
                            position={'relative'}
                            _after={{
                                content: "''",
                                width: 'full',
                                height: '30%',
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bgGradient: 'linear(to-r, brand.200, brand.900)',
                                zIndex: -1,
                            }}>
                            Emanuele Viganò
                        </Text>
                    </Heading>
                    <Text
                        as="p"
                        marginTop="2"
                        color={useColorModeValue('gray.700', 'gray.200')}
                        fontSize="lg">
                        <div dangerouslySetInnerHTML={{ __html: descriptionText }} />
                    </Text>
                    <Tag mt={4} maxW={'8.5rem'} onClick={() => openInNewTab(cvurl.publicUrl)} style={{ cursor: 'pointer' }} p={1} px={3} as={'b'} size='md' colorScheme='green' borderRadius='full'>
                        <TagLabel> CURRICULUM <ArrowForwardIcon mb={'4%'} /></TagLabel>
                    </Tag>
                </Box>
            </Box>

        </Container>
    );
};

export default AboutMe;