import {
  Avatar,
  TagLabel,
  Tag,
} from "@chakra-ui/react"
import badge from '../../img/ema_profile.jpg'


export const BadgeNameOutline = () => {
  return(
    <Tag size='lg'  borderRadius='lg'  color='dark' bg={'white'}  variant='outline'  boxShadow={'dark-lg'}>
      <Avatar
        src={badge}
        size='xs'
        name='Emanuele Viganò'
        ml={-1}
        mr={2}
      />
      <TagLabel pb={0.5}>Emanuele Viganò</TagLabel>
    </Tag>
)}
