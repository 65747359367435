import {
    Avatar,
    Tag,
    TagLabel
} from "@chakra-ui/react";
import badge from '../../img/ema_profile.jpg';


export const BadgeAdmin = () => {
    return (
        <Tag size='lg' borderRadius='lg' color='dark' bg={'white'} variant='outline' boxShadow={'dark-lg'}>
            <Avatar
                src={badge}
                size='xs'
                name='Ema'
                ml={-1}
                mr={2}
            />
            <TagLabel pb={0.5}>_Emanuele_</TagLabel>
        </Tag>
    )
}
