import { Box, Container, Portal, useDisclosure } from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { isNavbarFixedAtom, sidebarVariantAtom, supabaseAuthAtom } from "../../atoms";
import { useActiveRoute } from '../../hooks/useActiveRoute';
import Auth from '../Auth';
import AdminNavbar from './Navbars/AdminNavbar';
import Sidebar from './Sidebar';
import routes from './routes';


export function AdminPage() {
    const navigate = useNavigate();
    const [session] = useAtom(supabaseAuthAtom);
    const sidebarVariant = useAtomValue(sidebarVariantAtom);
    const fixed = useAtomValue(isNavbarFixedAtom);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const activeRoute = useActiveRoute(routes);

    if (!session) {
        return (<Auth />)
    }
    else {

        return (
            <>
                <Sidebar
                    routes={routes}
                    logoText={'ADMIN PANEL'}
                    display='none'
                    sidebarVariant={sidebarVariant}
                />

                <Container w={{
                    base: '100%',
                    xl: 'calc(100vw - 275px)'
                }}
                    ml={'275px'}>
                    <Portal>
                        <AdminNavbar
                            onOpen={onOpen}
                            brandText={activeRoute}
                            secondary={false}
                            fixed={fixed}
                        />
                    </Portal>
                    <Box
                        pt={'7rem'} >
                        <Box w={'calc(100vw - 280px)'} h={'100vh'} >
                            ciao
                        </Box>
                    </Box>
                </Container >
            </>
        );

    }
}