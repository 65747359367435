
import { useEffect, useState } from 'react';

export const useActiveRoute = (routes: any) => {
    const [activeRoute, setActiveRoute] = useState('.');
    useEffect(() => {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].path) {
                if (window.location.pathname === routes[i].path) {
                    setActiveRoute(routes[i].name);
                    break;
                }
            }
        }
    }, [routes, activeRoute]);

    return activeRoute;
};