import { Button, VStack, Container, Image, Stack, Text, Center, Code, Spacer } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import footerLogo from '../loghi/logo_ema_noicon_crop.png';
import { ArrowBackIcon } from '@chakra-ui/icons';


export function NotFound() {
    const navigate = useNavigate()

    return (
        <>
            <Container mt={'8rem'}>
                <Center>
                    <VStack spacing={4}>
                        <Code colorScheme='green' variant={'outline'} size={'40px'}>404</Code>
                        <Text as='b' >Hai trovato un posto segreto!</Text>
                        <Code colorScheme='green' variant={'outline'} size={'40px'}>
                            Sfortunatamente, questa è solo una pagina 404.
                        </Code>
                        <Text>
                            Potresti aver sbagliato a scrivere l'indirizzo,
                        </Text>
                        <Text m={'8rem'}>
                            o la pagina è satata spostata su un altro URL.
                        </Text>
                        <Spacer/>
                        <Button mt='3rem' leftIcon={<ArrowBackIcon />} bg="brand.900" color={'black'}
                            _after={{
                                transition: 'all .3s ease',
                                content: '""',
                                w: 'full',
                                h: 'full',
                                pos: 'absolute',
                                top: 2,
                                left: 0,
                                backgroundColor: 'brand.900',
                                filter: 'blur(20px)',
                                zIndex: -1,
                            }}
                            _groupHover={{
                                _after: {
                                    filter: 'blur(25px)',
                                },
                            }}
                            onClick={() => { navigate('/') }} >
                            Take me back to home page
                        </Button>
                        <Container
                            as={Stack}
                            maxW={'6xl'}
                            mx={8}
                            py={8}
                            spacing={4}
                            justify={'center'}
                            align={'center'}>
                            <Image src={footerLogo} boxSize='6rem' objectFit='contain' zIndex={2} />
                            {//  -----------------------sections scroll links (how to pass ref in this component? boh) 
                                //  <Stack direction={'row'} spacing={6}>
                                //    <Link href={'#'}>Home</Link>
                                //    <Link href={'#'}>Servizi</Link>
                                //    <Link href={'#'}>Chi Sono</Link>
                                //  </Stack>
                            }
                        </Container>
                    </VStack>
                </Center>
            </Container >
        </>
    );
}