import { Box } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableDatactAtom, supabaseAuthAtom } from "../../../atoms";
import { supabase } from '../../../supabaseClient';
import Auth from '../../Auth';
import { SimpleNav } from '../../navigation/SimpleNav';


interface contactObj { name: string, email: string, message: string, read: boolean };

export function StatsPage() {
    const navigate = useNavigate()
    const [session] = useAtom(supabaseAuthAtom);
    const [tableData, setTableData] = useAtom(TableDatactAtom);
    const [readTableData, setReadTableData]  = useState<any>([]);
    const [unreadTableData, setUnreadTableData]  = useState<any>([]);

    const fetchData = async () => {
        const { data, error }: any = await supabase
            .from('contact_form')
            .select()
        if (error) console.log(error);
        else setTableData(data);
    }

    useEffect(() => {
        fetchData();
    }, [])
    useEffect(() => {
        setUnreadTableData((tableData || []).filter((obj: any) => obj.read !== true));
        setReadTableData((tableData || []).filter((obj: any) => obj.read === true));
    }, [tableData])

    if (!session) {
        return (<Auth />)
    }
    else {

        return (
            <>
                <SimpleNav />
                <Box mx={'2rem'} >
                    statistics page in progress ...
                </Box>

            </>
        );
    }
}