import { ArrowForwardIcon } from "@chakra-ui/icons"
import { Box, Button, HStack, Heading, Stack, Text, Hide, Show, VStack, Center, ButtonGroup } from "@chakra-ui/react"
import { motion } from "framer-motion"
import HouseAnimate from "./HouseAnimate"
import { AiOutlineWhatsApp } from "react-icons/ai"
import AnimatedArrowDown from "./animatedArrow/AnimatedArrowDown"

interface headerProps {
    title: string;
    subtitle: string;
    txt: string;
}

export const Header = ({ title, subtitle, txt }: headerProps) => {
    const whatsappLink = `https://wa.me/393402883373?text=Ciao%20Emanuele%20ho%20visto%20il%20tuo%20sito%20web%20e%20vorrei%20proporti:`
    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            <Hide below='md'>
                <HStack>
                    <motion.div
                        animate={{
                            x: '8rem',
                            opacity: 1,
                        }}
                        initial={{
                            opacity: 0.2
                        }}
                        transition={{
                            stiffness: 80,
                            type: 'spring'
                        }}
                    >
                        <Box mr='12rem' mt='4rem' maxW={'32rem'}>
                            <Heading fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}>
                                <Text
                                    as={'span'}
                                    position={'relative'}
                                    _after={{
                                        content: "''",
                                        width: 'full',
                                        height: '30%',
                                        position: 'absolute',
                                        bottom: 1,
                                        left: 0,
                                        bgGradient: 'linear(to-r, brand.200, brand.900)',
                                        zIndex: -1,
                                    }}> {title} </Text>
                                <br />
                                <Text color={'brand.900'} as={'span'}>
                                    {subtitle}
                                </Text>
                            </Heading>
                            <br />
                            <Hide below='lg'>
                                <Stack>
                                    <Text color={'gray.500'} fontSize={'lg'}>
                                        {txt}
                                    </Text>
                                </Stack>
                            </Hide>
                            <ButtonGroup>
                                <Button mt='3rem' variant={'outline'} rightIcon={<ArrowForwardIcon />} borderColor="#00AF66" color={'black'}

                                    _groupHover={{
                                        _after: {
                                            filter: 'blur(25px)',
                                        },
                                    }}>
                                    CONTATTAMI
                                </Button>
                                <Button mt='3rem' leftIcon={<AiOutlineWhatsApp size={'1.6rem'} />} bg="brand.900" color={'black'}
                                    _after={{
                                        transition: 'all .3s ease',
                                        content: '""',
                                        w: 'full',
                                        h: 'full',
                                        pos: 'absolute',
                                        top: 2,
                                        left: 0,
                                        backgroundColor: 'brand.900',
                                        filter: 'blur(20px)',
                                        zIndex: -1,
                                    }}
                                    _groupHover={{
                                        _after: {
                                            filter: 'blur(25px)',
                                        },
                                    }}
                                    onClick={() => openInNewTab(whatsappLink)}>
                                    Whatsapp
                                </Button>

                            </ButtonGroup>
                        </Box>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 0.8,
                            delay: 0.5,
                            ease: [0, 0.71, 0.2, 1.01],
                        }} >
                        {// ------------ LOGO -------------
                        }
                        <HouseAnimate />
                    </motion.div>
                </HStack>
            </Hide>
            <Show below='md'>
                <VStack>
                    <Center>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                                duration: 0.8,
                                delay: 0.5,
                                ease: [0, 0.71, 0.2, 1.01],
                            }} >
                            {// ------------ LOGO -------------
                            }
                            <HouseAnimate />
                        </motion.div>
                    </Center>
                    <Center>
                        <motion.div
                            animate={{
                                y: '-8rem',
                                opacity: 1,
                            }}
                            initial={{
                                opacity: 0.2
                            }}
                            transition={{
                                stiffness: 80,
                                type: 'spring'
                            }} >
                            <Box mt={'8rem'} mb={'-8rem'} textAlign={'center'} alignItems={'center'}>
                                <Heading fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}>
                                    <Text
                                        as={'span'}
                                        position={'relative'}
                                        _after={{
                                            content: "''",
                                            width: 'full',
                                            height: '30%',
                                            position: 'absolute',
                                            bottom: 1,
                                            left: 0,
                                            bgGradient: 'linear(to-r, brand.200, brand.900)',
                                            zIndex: -1,
                                        }}> {title} </Text>
                                    <br />
                                    <Text color={'brand.900'} as={'span'}>
                                        {subtitle}
                                    </Text>
                                </Heading>
                                <br />
                                <Stack>
                                    <Text color={'gray.500'} fontSize={'lg'}>
                                        {txt}
                                    </Text>
                                </Stack>
                                <Button mt='3rem' rightIcon={<ArrowForwardIcon />} bg="brand.900" color={'black'}
                                    _after={{
                                        transition: 'all .3s ease',
                                        content: '""',
                                        w: 'full',
                                        h: 'full',
                                        pos: 'absolute',
                                        top: 2,
                                        left: 0,
                                        backgroundColor: 'brand.900',
                                        filter: 'blur(20px)',
                                        zIndex: -1,
                                    }}
                                    _groupHover={{
                                        _after: {
                                            filter: 'blur(25px)',
                                        },
                                    }}>
                                    CONTATTAMI
                                </Button>
                            </Box>
                        </motion.div>
                    </Center>
                </VStack>
            </Show>

            <AnimatedArrowDown />
        </>
    )
}