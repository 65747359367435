import { atom } from "jotai";

export const supabaseAuthAtom: any = atom(null);
export const userAtom: any = atom(undefined);
export const supabaseClient: any = atom(null);
export const TableDatactAtom = atom<any[]>([]);
export const contentDataAtom = atom<any[]>([]);
export const costumerPageDataAtom = atom<any[]>([]);
export const sidebarVariantAtom = atom<string>("opaque"); // opaque, transparent
export const isNavbarFixedAtom = atom<boolean>(true);
export const colorModeAtom = atom<string>("light"); // light, dark
