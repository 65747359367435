// import

import {
  HomeIcon,
  StatsIcon,
  UnreadMessageIcon,
  EditIcon,
  PersonIcon
} from "../Icons/Icons";


var dashRoutes = [
  {
    path: "/admin",
    name: "Dashboard",
    icon: <HomeIcon color="inherit" />,
  },
  {
    path: "/admin/inbox",
    name: "Manage Contacts",
    icon: <UnreadMessageIcon color="inherit" />,
  },
  {
    path: "/admin/statistics",
    name: "Statistics",
    icon: <StatsIcon color="inherit" />,
  },
  {
    path: "/admin/aboutme",
    name: "Edit Content",
    icon: <EditIcon color="inherit" />,
  },
  {
    path: "/admin/profile",
    name: "Profile",
    icon: <PersonIcon color="inherit" />,
  },

];
export default dashRoutes;
