import { Avatar, Box, Button, Center, Container, HStack, Hide, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Show, Spacer, Stack, Tag, TagLabel, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import footerLogo from '../../loghi/logo_ema_noicon_crop.png';
import { HamburgerIcon } from "@chakra-ui/icons";
import { BadgeAdmin } from "../badges/BadgeAdmin";

export const SimpleNav = () => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <Hide below='md'>
                <HStack mx='8rem'>
                    <HStack borderRadius={'lg'} >
                        <div style={{ cursor: 'pointer' }} onClick={() => {
                            onOpen()
                        }}
                        >
                           <BadgeAdmin/>
                        </div>
                    </HStack>
                    <Spacer />
                    <Center>
                        <Container
                            as={Stack}
                            maxW={'6xl'}
                            py={2}
                            spacing={4}
                            justify={'center'}
                            align={'center'}>
                            <Image src={footerLogo} boxSize='4rem' objectFit='contain' zIndex={2} />
                        </Container>
                    </Center>
                    <Spacer />
                    <HStack spacing={4} mt='2.5rem' >
                        <Tag
                            pb={0.3}
                            size='sm'
                            borderRadius='full'
                            variant='solid'
                            bg='white'
                            color='dark'
                            boxShadow={'xs'}
                            onClick={() => navigate('/admin')}
                            style={{ cursor: 'pointer' }}
                        >
                            <TagLabel>HOME</TagLabel>
                        </Tag>
                        <Tag
                            pb={0.3}
                            size='sm'
                            borderRadius='full'
                            variant='solid'
                            bg='white'
                            color='dark'
                            boxShadow={'xs'}
                            onClick={() => navigate('/')}
                            style={{ cursor: 'pointer' }}
                        >
                            <TagLabel>WEBSITE</TagLabel>
                        </Tag>
                        {//<ColorModeSwitcher justifySelf="flex-end" />
                        }
                    </HStack>
                </HStack>
            </Hide>
            {// ---------------- MOBILE MENU ----------------
            }
            <Show below='md'>
                <Box ml={'1.5rem'} mt={'-1.2rem'} position={'fixed'} borderRadius={'lg'} zIndex={55}>
                    <Menu>
                        <MenuButton boxShadow={'lg'}
                            as={IconButton}
                            aria-label='Options'
                            icon={<HamburgerIcon />}
                            variant={'outline'}
                        />
                        <MenuList>
                            <MenuItem command='⌂'
                                onClick={() => navigate('/admin')}>
                                ADMIN
                            </MenuItem>
                            <MenuItem command='🌐'
                                onClick={() => navigate('/')}>
                                WEBSITE
                            </MenuItem>
                            <MenuItem >
                                {//<ColorModeSwitcher justifySelf="flex-end" />
                                }
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
                <Center my='2.5rem' alignItems={'center'} textAlign={'center'} mt={'2.5rem'} >
                    <div style={{ cursor: 'pointer', position: 'fixed', zIndex: '55' }}
                        onClick={() => {
                            onOpen()
                        }}
                    >
                        <BadgeAdmin />
                    </div>
                </Center>

            </Show>
            {
                //---------modal--------
            }
            <Modal isCentered isOpen={isOpen} onClose={onClose} size='4xl'>
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent>
                    <ModalCloseButton color='brand.900' />
                    <ModalBody>
                        Hi user! you discover the helper center:
                        The corrent version af the website you are using
                        offers the possibilities to view contact messages
                        from the website form and mask them as read.
                        Other than let you edit your website content!
                    </ModalBody>
                    <ModalFooter>
                        <Button mt='3rem' bg="brand.900" color={'black'}
                            _after={{
                                transition: 'all .3s ease',
                                content: '""',
                                w: 'full',
                                h: 'full',
                                pos: 'absolute',
                                top: 2,
                                left: 0,
                                backgroundColor: 'brand.900',
                                filter: 'blur(20px)',
                                zIndex: -1,
                            }}
                            _groupHover={{
                                _after: {
                                    filter: 'blur(25px)',
                                },
                            }} onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </>
    )
}