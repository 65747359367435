import { HamburgerIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Grid,
  HStack,
  Hide,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Show,
  Spacer,
  Tag,
  TagLabel,
  VStack,
  createIcon,
  extendTheme,
  useColorMode,
  useDisclosure
} from "@chakra-ui/react"
import { wrap } from "@motionone/utils"
import {
  motion,
  useAnimationFrame,
  useMotionValue,
  useScroll,
  useSpring,
  useTransform,
  useVelocity
} from "framer-motion"
import { useAtom } from "jotai"
import { useEffect, useRef } from "react"
import { Route, Routes, useLocation } from 'react-router-dom'
import './App.css'
import { supabaseAuthAtom, supabaseClient } from "./atoms"
import AboutMe from "./components/AboutMe"
import { BadgeNameOutline } from "./components/badges/BadgeNameOutline"
import ContactForm from "./components/ContactForm"
import Footer from "./components/Footer"
import { Header } from "./components/Header"
import { NotFound } from "./components/NotFound"
import { AdminPage } from "./components/admin/AdminPage"
import { InboxPage } from "./components/admin/inbox/InboxPage"
import CardsGrid from './components/cards/CardsGrid'
import { supabase } from './supabaseClient'
import { EditPage } from "./components/admin/edit/EditPage"
import { StatsPage } from "./components/admin/statistics/StatsPage"
import ReactGA from 'react-ga4';

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  initialColorMode: 'light',
  useSystemColorMode: false,
  styles: {
    global: {
      // styles for the `body`
      body: {

        //color: 'white',
      },
      // styles for the `a`
      button: {
        color: "#BEF5CF",//bottone call to action
        _hover: {
          color: "#E5FBEC",//blob sfondo
        },
      },
    },
  },
  colors: {
    brand: {
      100: "#E5FBEC",//blob sfondo
      200: "#BEF5CF",//bottone call to action
      300: "linear-gradient(to right top, #bef5cf, #9befb1, #77e890, #4fe16c, #00d841);",//navbar/sidebar
      900: "#00D841",//original animation
    },
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
  },
})

//ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || '');

export const App = () => {
  const [session, setSession] = useAtom(supabaseAuthAtom);
  const [supClient, setSupClient] = useAtom(supabaseClient);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
    setSupClient(supabase)
    return () => subscription.unsubscribe()
  }, [])


  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/admin" >
          <Route index element={<AdminPage />} />
          <Route path="/admin/inbox" element={<InboxPage />} />
          <Route path="/admin/aboutme" element={<EditPage />} />
          <Route path="/admin/statistics" element={<StatsPage />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ChakraProvider>
  )
};


export const Homepage = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  const home = useRef(null)
  const services = useRef(null)
  const aboutMe = useRef(null)
  const contacts = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const sectionItem = ['contacts', 'services', 'aboutMe']
  const location = useLocation();
  console.log('GA initialize')
  const headerTitle = 'ARBORICOLTORE';
  const headerSubtitle = 'Cura, Potatura e abbattimenti in treeclimbing';
  const headerTxt = '';

  // useEffect(() => {
  // Attiva Google Analytics solo quando la route corrente è "/"
  //   const unlisten = () => {

  //     console.log('GA send pageview')
  //     ReactGA.set({ page: location.pathname });
  //     ReactGA.send({ hitType: "pageview", page: location.pathname, title: "landing page" });
  //   };

  // Pulisci il listener quando il componente viene smontato
  //   return () => {
  //     unlisten();
  //   };
  // }, [location.pathname]);


  const scrollToSection = (sectionRef: any) => {
    window.scrollTo({
      top: sectionRef.current.offsetTop,
      behavior: 'smooth',
    })
  }

  const navItems = {
    contacts: 'CONTATTAMI',
    services: 'SERVIZI',
    who_i_am: 'CHI SONO',
  }

  return (
    <ChakraProvider theme={theme}>
      {// ---------------- BACKGROUND BLOB (desktop only) ----------------
      }
      <Hide below='lg'>
        <svg style={{ position: "absolute", top: "0", right: "0", zIndex: '-2' }} viewBox="0 0 680 416" xmlns="http://www.w3.org/2000/svg" >
          <path opacity=".1" d="M 339.883 0 C 321.96 37.568 326.667 75.98 354 115.238 c 41 58.887 138.227 21.23 183.906 98.434 c 30.453 51.469 82.151 79.28 155.094 83.433 V 0 H 339.883 z" fill="#00D841" width="100%" />
        </svg>
      </Hide>
      {// ---------------- DESKTOP MENU ----------------
      }
      <Hide below='md'>
        <HStack mx='8rem'>
          <HStack spacing={4} mt='2.5rem' borderRadius={'lg'} zIndex={-1} >
            <Box mt={2} h={'2rem'} w={'4rem'}>

            </Box>
          </HStack>
          <HStack spacing={4} mt='2.5rem' position={'fixed'} borderRadius={'lg'} zIndex={55} >
            <div style={{ cursor: 'pointer' }}
              onClick={() => {
                onOpen()
              }}
            >
              <BadgeNameOutline />
            </div>
          </HStack>
          <Spacer />
          <HStack spacing={4} mt='2.5rem' >
            <Tag
              pb={0.3}
              size='sm'
              borderRadius='full'
              variant='solid'
              bg='white'
              color='dark'
              boxShadow={'xs'}
              onClick={() => scrollToSection(contacts)}
            >
              <TagLabel>{navItems.contacts}</TagLabel>
            </Tag>
            <Tag
              pb={0.3}
              size='sm'
              borderRadius='full'
              variant='solid'
              bg='white'
              color='dark'
              boxShadow={'xs'}
              onClick={() => scrollToSection(services)}
            >
              <TagLabel>{navItems.services}</TagLabel>
            </Tag>
            <Tag
              pb={0.3}
              size='sm'
              borderRadius='full'
              variant='solid'
              bg='white'
              color='dark'
              boxShadow={'xs'}
              onClick={() => scrollToSection(aboutMe)}
            >
              <TagLabel>{navItems.who_i_am}</TagLabel>
            </Tag>
            {//<ColorModeSwitcher justifySelf="flex-end" />
            }
          </HStack>

        </HStack>
      </Hide>

      {// ---------------- MOBILE MENU ----------------
      }
      <Show below='md'>
        <Box ml={'1.5rem'} mt={'-1.2rem'} position={'fixed'} borderRadius={'lg'} zIndex={55}>
          <Menu>
            <MenuButton boxShadow={'lg'}
              bgColor={'white'}
              as={IconButton}
              aria-label='Options'
              icon={<HamburgerIcon />}
              variant={'outline'}
              size={'lg'}
            />
            <MenuList>
              <MenuItem command=''
                onClick={() => scrollToSection(contacts)}>
                {navItems.contacts}
              </MenuItem>
              <MenuItem command=''
                onClick={() => scrollToSection(services)}>
                {navItems.services}
              </MenuItem>
              <MenuItem command=''
                onClick={() => scrollToSection(aboutMe)}>
                {navItems.who_i_am}
              </MenuItem>
              <MenuItem >
                {//<ColorModeSwitcher justifySelf="flex-end" />
                }
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Center my='2.5rem' alignItems={'center'} textAlign={'center'} mt={'2.5rem'} >
          <div style={{ cursor: 'pointer', position: 'fixed', zIndex: '55' }}
            onClick={() => {
              onOpen()
            }}
          >
            <BadgeNameOutline />
          </div>
        </Center>

      </Show>

      {// ---------------- HEADER ----------------
      }
      <div onClick={() => scrollToSection(contacts)}>
        <Header
          title={headerTitle}
          subtitle={headerSubtitle}
          txt={headerTxt} />
      </div>
      {// ---------------- START BODY ----------------------------------------------
      }
      <Box textAlign="center" fontSize="xl" ref={services}>
        <Grid minH="60vh" p={3}>
          <VStack spacing={2} pt={'6rem'}>
            <motion.div
              animate={{
                y: '-8rem',
                opacity: 1,
              }}
              initial={{
                opacity: 0.1
              }}
              transition={{
                stiffness: 80,
                type: 'spring',
                delay: 0.2,
              }}
            >
              {// ------------ CARDS GRID -------------
              }
              <CardsGrid />
            </motion.div>
          </VStack>
        </Grid>
      </Box>

      {// ------------ SCROLL TEXT -------------
      }
      <Box color='dark' className='scroller-container' boxShadow={'xl'}>
        <section>
          <ParallaxText boxShadow={'2xl'} baseVelocity={-5}>Emanuele Vigano'・</ParallaxText>
          <ParallaxText baseVelocity={5} ><p > Arboricoltore・Potature・Abbattimenti・</p></ParallaxText>
        </section>
      </Box>

      <Box alignItems="center" fontSize="xl"  >
        <Grid minH="100vh" p={3}>
          <VStack spacing={2} mt={'4rem'}>

            {// ------------ ABOUT ME -------------
            }
            <section ref={aboutMe}>
              <AboutMe />
            </section>
            <Spacer />
            <Spacer />
            {// ------------ CONTACT FORM -------------
            }
            <section ref={contacts}>
              <ContactForm />
            </section>
          </VStack>
        </Grid>
      </Box>
      {// ------------ FOOTER -------------
      }
      <Footer />

      {// ------------ MODAL -------------
      }
      <Modal isCentered isOpen={isOpen} onClose={onClose} size='5xl'>
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px) hue-rotate(90deg)'
        />
        <ModalContent>
          <ModalBody>
            <AboutMe />
          </ModalBody>
          <ModalFooter>
            <Button mt={0} bg="brand.900" color={'black'}
              _after={{
                transition: 'all .3s ease',
                content: '""',
                w: 'full',
                h: 'full',
                pos: 'absolute',
                top: 2,
                left: 0,
                backgroundColor: 'brand.900',
                filter: 'blur(20px)',
                zIndex: -1,
              }}
              _groupHover={{
                _after: {
                  filter: 'blur(25px)',
                },
              }} onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


    </ChakraProvider>
  )
}




const Arrow = createIcon({
  displayName: 'Arrow',
  viewBox: '0 0 72 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
      fill="currentColor"
    />
  ),
});








export const ParallaxText = ({ children, baseVelocity = 100 }: any) => {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false
  });

  /**
   * This is a magic wrapping for the length of the text - you
   * have to replace for wrapping that works for you or dynamically
   * calculate
   */
  const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

  const directionFactor = useRef(1);
  useAnimationFrame((timestamp: number) => {
    let moveBy = directionFactor.current * baseVelocity * (8 / 1000);

    /**
     * This is what changes the direction of the scroll once we
     * switch scrolling directions.
     */
    if (velocityFactor.get() < 0) {
      directionFactor.current = -1;
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1;
    }

    moveBy += directionFactor.current * moveBy * velocityFactor.get();

    baseX.set(baseX.get() + moveBy);
  });

  /**
   * The number of times to repeat the child text should be dynamically calculated
   * based on the size of the text and viewport. Likewise, the x motion value is
   * currently wrapped between -20 and -45% - this 25% is derived from the fact
   * we have four children (100% / 4). This would also want deriving from the
   * dynamically generated number of children.
   */
  return (
    <div className="parallax">
      <motion.div className="scroller" style={{ x }}>
        <span className="scroll-text">{children} </span>
        <span className="scroll-text">{children} </span>
        <span className="scroll-text">{children} </span>
        <span className="scroll-text">{children} </span>
      </motion.div>
    </div>
  );
}


